.grid-mb {
  .g3-1 {
    @media screen and (max-width: 575px) {
      max-width: 20% !important;
      flex-basis: 20% !important;
    }
    .icon {
      @media screen and (max-width: 575px) {
        width: 100% !important;
      }
    }
  }
  .g3-2 {
    text-align: start !important;
    @media screen and (max-width: 575px) {
      max-width: 80% !important;
      flex-basis: 80% !important;
      text-align: center !important;
    }
    .icon {
      @media screen and (max-width: 575px) {
        width: 100% !important;
      }
    }
  }
}

.carousel {
  width: 20%;
  margin: auto;
  @media screen and (max-width: 575px) {
    width: 50%;
  }
}

.full-mb {
  @media screen and (max-width: 575px) {
    max-width: 100% !important;
    flex-basis: 100% !important;
    text-align: center !important;
  }

  .g3-1 {
    @media screen and (max-width: 575px) {
      max-width: 20% !important;
      flex-basis: 20% !important;
    }
    .icon {
      @media screen and (max-width: 575px) {
        width: 100% !important;
      }
    }
  }
  .g3-2 {
    text-align: start !important;
    @media screen and (max-width: 575px) {
      max-width: 60% !important;
      flex-basis: 60% !important;
      text-align: center !important;
    }
    .icon {
      @media screen and (max-width: 575px) {
        width: 100% !important;
      }
    }
  }
}

.makeStyles-paper-42 {
  @media screen and (max-width: 575px) {
    width: 50% !important;
  }
}

.carousel-container {
  @media screen and (max-width: 575px) {
    width: 100% !important;
  }
}

.jRFJkP,
.cNNJIt {
  @media screen and (max-width: 575px) {
    font-size: 1em !important;
    width: 2rem !important;
    height: 2rem !important;
    min-width: 2rem !important;
    line-height: 0.5rem !important;
  }
}

.cust-grid{
  @media screen and (max-width: 575px) {
  max-width: 100% !important;
  }
}
.cust-card{
  width: 30%;
  text-align: left;
  @media screen and (max-width: 575px) {
    width: 100%;
    }
}