.MuiBox-root{
    text-align: center !important;
}
.MuiTabs-flexContainer{
    justify-content: space-around !important;
}
.MuiTab-root{
    min-width: 50% !important;
}
.Mui-selected{
    color: $secondary !important;
}
.btn-container{
    display: flex;
    width: 90%;
    justify-content: end;

    & button{
        margin: 0 0.5rem;
    }
}