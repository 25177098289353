/*Login Styles*/
/*Login Styles*/

.login-container {
  position: relative;
  height: 100%;
}

.login-content {
  width: 420px;
  margin: auto;
  padding: 35px 35px 20px;
  background-color: white;
  @include box-shadow($shadow);
  @include border-radius(4px);
  font-size: 14px;
  max-width: 94%;
}

.login-header {
  margin-bottom: 30px;
}

.login-content .form-control {
  background-color: map_get($gray, lighten-4);

  &:focus {
    box-shadow: none;
    border-color: $app-primary;
  }
}

.login-content .btn,
.login-content .jr-btn {
  padding: 10px 35px !important;
}

.app-login-container {
  position: relative;
  max-width: 680px;
  width: 94%;
  margin: 0 auto;
  @media screen and (max-width: 575px) {
    width: 100%;
    order: 2;
    margin: 2rem auto;
  }

  & .loader-view {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    top: 0;
    bottom: 0;
    z-index: 2;
  }
}
.app-register-container {
  margin-top: -15rem;
  @media screen and (max-width: 575px) {
    margin-top: -2rem;
  }
}
.app-login-container-2 {
  margin-top: 8rem;
}
.app-login-main-content {
  @include display-flex();
  background-color: white;
  @include box-shadow($shadow);
  @include border-radius(10px);
  font-size: 14px;
  border-radius: 1rem;
  //overflow: hidden;
  // margin: 1rem;
  @media screen and (max-width: 575px) {
    margin: auto 1.5rem;
  }
}
.app-login-main-content-2 {
  background-color: #ffffffab;
  width: 85%;
  @media screen and (max-width: 575px) {
    width: 100%;
    margin: 0;
  }
}

.app-login-content {
  padding: 35px 35px 20px;
  width: 60%;
  order: 1;

  @media screen and (max-width: 575px) {
    width: 100%;
    order: 2;
  }
}

.app-login-content-2 {
  padding: 35px 35px 20px;
  width: 100%;
  @media screen and (max-width: 575px) {
    padding: 1rem;
  }
}
.app-login-header {
  margin-bottom: 1rem;
}

.app-login-content .form-control {
  background-color: map_get($gray, lighten-4);

  &:focus {
    box-shadow: none;
    border-color: $app-primary;
  }
}

.app-login-content .btn,
.login-content .jr-btn {
  padding: 10px 35px !important;
}

.app-logo-content {
  background-color: $secondary;
  padding: 35px 35px 20px;
  width: 40%;
  order: 2;
  background-image: linear-gradient(to bottom right, $light-pink, $secondary);

  @media screen and (max-width: 575px) {
    width: 100%;
    order: 1;
    border-radius: 10px 10px 0 0;
  }
}
.app-logo-content img {
  width: 100%;
}

.app-social-block {
  @include display-flex();
  @include align-items(center);
  @include justify-content(space-between);

  & .social-link,
  & .social-link li {
    margin: 0;
  }

  & .social-link button {
    border: solid 1px $app-primary;
    @include border-radius(50%);
    color: $app-primary;
    padding: 0;

    &:hover,
    &:focus {
      color: white;
      background-color: $app-primary;
    }
  }

  & p {
    margin-bottom: 0;
  }
}

.logo-form {
  width: 20%;
  position: absolute;
  top: -3rem;
  right: -2rem;
  & img {
    transform: rotateZ(-35deg);
  }
  @media screen and (max-width: 575px) {
    top: -2rem;
    right: -1rem;
  }
}

.gender-selected {
  & svg:nth-child(1) {
    color: rgba(0, 0, 0, 0.54);
  }
  & svg:nth-child(2) {
    color: #d61f5f;
  }
}
.login-form {
  @media only screen and (max-width: 575px) {
    margin: 2rem !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
}
.main-logo {
  margin: 1rem !important;
  @media only screen and (max-width: 575px) {
    margin: 2rem !important;
    max-width: 50% !important;
    flex-basis: 50% !important;
  }
}

.not-robot {
  & div {
    & div {
      margin: 0.5rem auto;
    }
  }
}
