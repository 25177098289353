.setting-container {
  width: 70%;
  margin: auto;
  @media screen and (max-width: 575px) {
    margin-top: 25%;
    width: 100%;
  }
  & .setting-tap {
    margin-bottom: 1rem;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 1px rgba(0, 0, 0, 0.4), 0px 1px 3px 1px rgba(0, 0, 0, 0.12) !important;
    background-color: #f4f4f7;
  }

  & .after-sec {
    position: relative;
    &:after {
      content: "";
      width: 2px;
      height: 90%;
      background-color: $secondary;
      position: absolute;
      right: 0px;
      bottom: 0px;
      margin: auto;
      // display: block;
      @media screen and (max-width: 575px) {
        width: 100%;
        height: 1px;
      }
    }
  }
  & .sub-title {
    color: $secondary;
    padding: 1rem;
    font-weight: bold;
    @media screen and (max-width: 575px) {
      padding: 0.5rem 0rem 0;
    }
  }
  & .info-container {
    @media screen and (max-width: 575px) {
      flex-grow: 0;
      max-width: 100% !important;
      flex-basis: 100% !important;
    }
  }
  & .info {
    margin: 0.5rem;
  }
}

.confirm-del {
  width: 50%;
  margin: auto;
  @media screen and (max-width: 575px) {
    width: auto;
    margin: auto 1.5rem;
  }
}
